import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
} from "../../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import {
  setCCDetails,
  setPaymentResolvedByChat,
  setPaymentVerificationAttempt,
} from "../reducers/paymentReducer";
import updateVisitor from "../../../Appsync/updateVisitor";
import { do_encrypt } from "../../../../helpers/xdr-encrypt";
import {
  getMaskedCCNum,
  getFormattedUserName,
  isEmpty,
} from "../../../../helpers/formatUtils";
import {
  processTransaction,
  getPciToken,
  chargeOrder,
  chargeOrderForCod,
} from "../actions/paymentApi";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { setChatReason } from "../../../Appsync/state/reducers";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { PaymentClient, Env } from "@backoffice/fast-payments-client-js-sdk";
import { sendAppsyncMessage } from "./../../../Appsync/sendAppsyncMessage";
import i18n from 'i18next';
 
export const submitPaymentDetails = (
  name,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvv,
  cardType
) => async (dispatch, getStore) => {
  let module = "";
  let showComponent = "";
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", "Submit payment"),
      createMessage("TEXT", "system", {
        key: "SystemMessage.ValidatingCardDetails",
      }),
    ])
  );
  await dispatch(
    updateApiProgress(i18n.t("Constants.API_PROGRESS.PAYMENT"), 40, COMPONENTS.PAYMENT, 0)
  );

  let paymentVerificationAttempt =
    getStore().claim.payment.paymentVerificationAttempt || 0;
  paymentVerificationAttempt++;

  await dispatch({
    type: setPaymentVerificationAttempt.toString(),
    payload: paymentVerificationAttempt,
  });

  let ccformask = getMaskedCCNum(cardNumber);
  let ccmask = ccformask.replace(/(\w{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
  await dispatch(setCCDetails(ccmask));

  const { PostalCode } = getStore().claim.schedule.registeredAddress;
  const {
    PCIToken,
    Encryptionkey,
    ReferenceId,
  } = getStore().claim.payment.pciToken.SecurityToken;
  const { CacheId } = getStore().session.sessionData.Configurations;
  
  let ServiceOrderId;

  const enquiryOption = getStore().journeyMessages.enquiryOption;

  // check for Service Order Id

  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (enquiryOption === EnumEnquiryModules.ResumeRequest) {
    // get service order from determine incident response as service order is called before
    ServiceOrderId = getStore().serviceRequest.determineIncidentDetails
      .ServiceOrder.ServiceOrderId;
  }

  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const { correctedPickupAddress } = getStore().claim.schedule;
  const resolvedByChat = getStore().claim.payment.resolvedByChat;
  const customerName = getFormattedUserName(name);
  const panData = window.encodeURIComponent(
    do_encrypt(cardNumber, cvv, Encryptionkey)
  );

  // const processTransactionResponse = await dispatch(
  //   processTransaction(
  //     PCIToken,
  //     expiryMonth,
  //     expiryYear,
  //     customerName.firstName,
  //     customerName.lastName,
  //     PostalCode,
  //     panData
  //   )
  // ).catch((err) => dispatch(serviceUnavailable()));
  // if (isEmpty(processTransactionResponse)) return;

  // let res = processTransactionResponse.ProcessTransactionResponse;
  // let [code, msg] = res.split(",");
  // let rtn_code = code.split(":")[1].trim();
  // let chatConnected = false;

  // const processTransactionResponse = await dispatch(
  //   processTransaction(
  //     PCIToken,
  //     expiryMonth,
  //     expiryYear,
  //     customerName.firstName,
  //     customerName.lastName,
  //     PostalCode,
  //     panData
  //   )
  // ).catch((err) => {
  //   //dispatch(serviceUnavailable())
  //   console.log("processTransaction API error:", err)
  //   return null;
  // });

  const paymentClient = new PaymentClient({
    env:
      process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD"
        ? Env["prod-apac"]
        : Env["qa-apac"],
  });

  const { mdn } = getStore().validation.inputData;
  const { EmailAddress } = getStore().validation.verification

  const billingContact = {
    name: {
      first: customerName.firstName,
      last: customerName.lastName,
    },
    address: {
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      country: "TH",
      zip: PostalCode || correctedPickupAddress?.PostalCode || "10540",
    },
    locale: {
      countryCodeISO3166: "TH",
      languageCodeISO639: "en",
    },
    contactInfo: {
      phone: mdn,
      email: EmailAddress || "",
    },
  };

  const creditCardInfo = {
    number: cardNumber,
    securityCode: cvv,
    expiration: {
      month: expiryMonth,
      year: expiryYear,
    },
  };

  let processFastPaymentResponse = null;

  try {
    await paymentClient.addSession(PCIToken, {
      encryptionKey: Encryptionkey,
      appName: "ENRPORTAL",
      currency: "THB", 
      //currency: "SGD", //TODO - revert
    });
    await paymentClient.addBillingContactInfo(billingContact);
    await paymentClient.addCreditCardInfo(creditCardInfo);

    processFastPaymentResponse = await paymentClient.processPayment();
    console.log("processFastPayment Response:", processFastPaymentResponse);
  } catch (err) {
    console.log("processFastPayment Error:", err)

    let loggedErrorResponse = "";
    try {
      loggedErrorResponse = err.toString();
    } catch (err) {
      console.log("error:", err)
    }
    const message = createMessage("TEXT", "system", loggedErrorResponse);
    await dispatch(sendAppsyncMessage({ ...message, content: message.data }));
  }

  // if (isEmpty(processTransactionResponse)) return;

  // let res = processTransactionResponse.ProcessTransactionResponse;
  // let [code, msg] = res.split(",");
  // let rtn_code = code.split(":")[1].trim();

  // let isProcessTransactionSuccess = !isEmpty(processTransactionResponse);
  let isProcessFastPaymentSuccess = !isEmpty(processFastPaymentResponse);

  // let res = isProcessTransactionSuccess ? processTransactionResponse.ProcessTransactionResponse : "";
  // let code = res.split(",")[0];
  // let rtn_code = code.length ? code.split(":")[1].trim() : "";

  let chatConnected = false;

  if (isProcessFastPaymentSuccess) {
    // charge Order api
    // const chargeOrderResponse = await dispatch(
    //   chargeOrder(
    //     CacheId,
    //     ServiceOrderId,
    //     ServiceRequestId,
    //     correctedPickupAddress.AddressId,
    //     cardType,
    //     PCIToken,
    //     parseInt(cvv),
    //     customerName.firstName,
    //     customerName.lastName,
    //     expiryYear,
    //     expiryMonth,
    //     PostalCode || correctedPickupAddress?.PostalCode || "819663",
    //     panData,
    //     ReferenceId
    //   )
    // ).catch((err) => dispatch(serviceUnavailable()));

    // if (isEmpty(chargeOrderResponse)) return;
    let chargeOrderResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      chargeOrderResponse = await dispatch(
        chargeOrder(
          CacheId,
          ServiceOrderId,
          ServiceRequestId,
          correctedPickupAddress.AddressId,
          cardType,
          PCIToken,
          parseInt(cvv),
          customerName.firstName,
          customerName.lastName,
          expiryYear,
          expiryMonth,
          PostalCode || correctedPickupAddress?.PostalCode || "819663",
          panData,
          ReferenceId
        )
      ).catch((err) => { return null });

      if (chargeOrderResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(chargeOrderResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    if ((chargeOrderResponse &&
      chargeOrderResponse.CreateChargeOrderResults &&
      chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse &&
      chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse.getTransactionByIdRspn &&
      chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse.getTransactionByIdRspn.status === "ProcessFailure") ||
      (chargeOrderResponse && chargeOrderResponse.CreateChargeOrderResults && !chargeOrderResponse.CreateChargeOrderResults.isChargeOrderSuccess)) {
      dispatch(serviceUnavailable())
      return
    }

    dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title"),
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count"),
        initial
      )
    );
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.CardValidatedSuccessfully",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.ReviewRepairRequest",
        }),
      ])
    );

    module = "REPAIR_DETAILS";
    showComponent = "";
  } else {
    await dispatch(
      addJourneyMessages([
        createMessage("PAYMENT", "system", {
          showComponent: "IncorrectCardDetails",
        }),
      ])
    );
    if (paymentVerificationAttempt < 3) {
      // call PCI toekn again
      const { CacheId } = getStore().session.sessionData.Configurations;
      dispatch(getPciToken(CacheId, 1)).catch((err) =>
        dispatch(serviceUnavailable())
      );

      module = "PAYMENT";
      showComponent = "SubmitCardDetails";
      dispatch(updatePaymentResolvedByChat(false));
    } else if (!resolvedByChat) {
      await dispatch(setChatReason("Invalid Card"));
      dispatch(udpateChatInputVisibility(true));
      chatConnected = true;
    }
  }
  // Update Api progress
  await dispatch(
    updateApiProgress(i18n.t("Constants.API_PROGRESS.PAYMENT_SUCCESS"), 100, COMPONENTS.PAYMENT, 40)
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  if (!chatConnected)
    await dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
};

export const submitPaymentDetailsNew = (
  paymentMethod,
  name,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvv,
  cardType
) => async (dispatch, getStore) => {
  let module = "";
  let showComponent = "";
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", "Submit payment"),
      createMessage("TEXT", "system", {
        key: "SystemMessage.ValidatingCardDetails",
      }),
    ])
  );
  await dispatch(
    updateApiProgress(API_PROGRESS.PAYMENT, 40, COMPONENTS.PAYMENT, 0)
  );

  let paymentVerificationAttempt =
    getStore().claim.payment.paymentVerificationAttempt || 0;
  paymentVerificationAttempt++;

  await dispatch({
    type: setPaymentVerificationAttempt.toString(),
    payload: paymentVerificationAttempt,
  });

  const { PostalCode } = getStore().claim.schedule.registeredAddress;
  const { correctedPickupAddress } = getStore().claim.schedule;
  const {
    PCIToken,
    Encryptionkey,
    ReferenceId,
  } = getStore().claim.payment.pciToken.SecurityToken;
  const { CacheId } = getStore().session.sessionData.Configurations;
  let ServiceOrderId;

  const enquiryOption = getStore().journeyMessages.enquiryOption;

  // check for Service Order Id
  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (enquiryOption === EnumEnquiryModules.ResumeRequest) {
    // get service order from determine incident response as service order is called before
    ServiceOrderId = getStore().serviceRequest.determineIncidentDetails
      .ServiceOrder.ServiceOrderId;
  }

  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;

  const { mdn } = getStore().validation.inputData;
  const { EmailAddress } = getStore().validation.verification
  const customerName = getFormattedUserName(name);

  let chargeOrderResponse;
  if (paymentMethod.toUpperCase() === "CREDITCARD") {
    let ccformask = getMaskedCCNum(cardNumber);
    let ccmask = ccformask.replace(/(\w{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
    await dispatch(setCCDetails(ccmask));    
    const panData = window.encodeURIComponent(
      do_encrypt(cardNumber, cvv, Encryptionkey)
    );

    // charge Order api
    // chargeOrderResponse = await dispatch(
    //   chargeOrder(
    //     CacheId,
    //     ServiceOrderId,
    //     ServiceRequestId,
    //     correctedPickupAddress.AddressId,
    //     cardType,
    //     PCIToken,
    //     parseInt(cvv),
    //     customerName.firstName,
    //     customerName.lastName,
    //     expiryYear,
    //     expiryMonth,
    //     PostalCode || correctedPickupAddress?.PostalCode || "819663",
    //     panData,
    //     ReferenceId
    //   )
    // ).catch((err) => dispatch(serviceUnavailable()));
    let retrycount = 0;
    while (retrycount < 2) {
      chargeOrderResponse = await dispatch(
        chargeOrder(
          CacheId,
          ServiceOrderId,
          ServiceRequestId,
          correctedPickupAddress.AddressId,
          cardType,
          PCIToken,
          parseInt(cvv),
          customerName.firstName,
          customerName.lastName,
          expiryYear,
          expiryMonth,
          PostalCode || correctedPickupAddress?.PostalCode || "819663",
          panData,
          ReferenceId
        )
      ).catch((err) => { return null });

      if (chargeOrderResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(chargeOrderResponse)) {
      dispatch(serviceUnavailable())
      return;
    }
  } else if (paymentMethod.toUpperCase() === "PAYPAL") {
    let ChargeOrder = {
      PaymentMethodType: "PYPL",
      ChargeOrderStatus: "PREAUTH",
      AddressId: correctedPickupAddress.AddressId,
      AdditionalChargeAuth: "false",
      ChargeOrderCardBrand: "PaypalAccount",
      ChargeOrderCardType: "PYPL",
      PCIToken: PCIToken,
      CardCheckNumber: 1,
      CardHolderFirstName: "",
      CardHolderLastName: "",
      ExpYear: "",
      ExpMonth: "",
      ZipCode: PostalCode || "819663",
      EncryptedPanData: "",
      ReferenceId: ReferenceId,
      IsPromotionDiscount: false,
      ServiceOrderId: ServiceOrderId,
    };

    // chargeOrderResponse = await dispatch(
    //   chargeOrder(
    //     CacheId,
    //     ServiceOrderId,
    //     ServiceRequestId,
    //     correctedPickupAddress.AddressId,
    //     "",
    //     PCIToken,
    //     1,
    //     customerName.firstName,
    //     customerName.lastName,
    //     "",
    //     "",
    //     PostalCode || correctedPickupAddress?.PostalCode || "819663",
    //     "",
    //     ReferenceId,
    //     ChargeOrder
    //   )
    // ).catch((err) => dispatch(serviceUnavailable()));    
    let retrycount = 0;
    while (retrycount < 2) {
      chargeOrderResponse = await dispatch(
        chargeOrder(
          CacheId,
          ServiceOrderId,
          ServiceRequestId,
          correctedPickupAddress.AddressId,
          "",
          PCIToken,
          1,
          customerName.firstName,
          customerName.lastName,
          "",
          "",
          PostalCode || correctedPickupAddress?.PostalCode || "819663",
          "",
          ReferenceId,
          ChargeOrder
        )
      ).catch((err) => { return null });

      if (chargeOrderResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(chargeOrderResponse)) {
      dispatch(serviceUnavailable())
      return;
    }
  }

  if (isEmpty(chargeOrderResponse)) return;

  if ((chargeOrderResponse &&
    chargeOrderResponse.CreateChargeOrderResults &&
    chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse &&
    chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse.getTransactionByIdRspn &&
    chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse.getTransactionByIdRspn.status === "ProcessFailure") ||
    (chargeOrderResponse && chargeOrderResponse.CreateChargeOrderResults && !chargeOrderResponse.CreateChargeOrderResults.isChargeOrderSuccess)) {
    dispatch(serviceUnavailable())
    return
  }

  dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
  const initial = getStore().journeyMessages.flowProgress.percentage;
  dispatch(
    updateFlowProgress(
      i18n.t("Constants.PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title"),
      i18n.t("Constants.PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count"),
      initial
    )
  );
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "system", {
        key: "SystemMessage.CardValidatedSuccessfully",
      }),
      createMessage("TEXT", "system", {
        key: "SystemMessage.ReviewRepairRequest",
      }),
    ])
  );

  module = "REPAIR_DETAILS";
  showComponent = "";

  await dispatch(
    updateApiProgress(i18n.t("Constants.API_PROGRESS.PAYMENT_SUCCESS"), 100, COMPONENTS.PAYMENT, 40)
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  await dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

const getServiceOrderId = (store) => {
  const serviceOrderId = store.claim.schedule?.serviceOrder?.CreateServiceOrderResults?.ServiceOrderId;
  if(serviceOrderId) return serviceOrderId;

  const { ServiceOrder } = store.serviceRequest.determineIncidentDetails;
  return ServiceOrder.ServiceOrderId;
}

export const submitCODPayment = () => async (dispatch, getStore) => {
  const { CacheId } = getStore().session.sessionData.Configurations;
  
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const { selectedPickupAddress } = getStore().claim.schedule;
  const ServiceOrderId = getServiceOrderId(getStore());

  // call charge Order
  await dispatch(
    updateApiProgress(i18n.t("Constants.API_PROGRESS.COD"), 40, COMPONENTS.PAYMENT, 0)
  );

  // charge Order api
  const chargeOrderResponse = await dispatch(
    chargeOrderForCod(
      CacheId,
      ServiceOrderId,
      ServiceRequestId,
      selectedPickupAddress.AddressId,
      ServiceOrderId
    )
  ).catch((err) => dispatch(serviceUnavailable()));

  if (isEmpty(chargeOrderResponse)) return;

  if ((chargeOrderResponse &&
    chargeOrderResponse.CreateChargeOrderResults &&
    chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse &&
    chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse.getTransactionByIdRspn &&
    chargeOrderResponse.CreateChargeOrderResults.getTransactionByIdResponse.getTransactionByIdRspn.status === "ProcessFailure") ||
    (chargeOrderResponse && chargeOrderResponse.CreateChargeOrderResults && !chargeOrderResponse.CreateChargeOrderResults.isChargeOrderSuccess)) {
    dispatch(serviceUnavailable())
    return
  }

  // Update Api progress
  await dispatch(
    updateApiProgress(i18n.t("Constants.API_PROGRESS.COD_SUCCESS"), 100, COMPONENTS.PAYMENT, 40)
  );
  await sleep(1000);
  await dispatch(clearApiProgress());

  dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
  const initial = getStore().journeyMessages.flowProgress.percentage;
  dispatch(
    updateFlowProgress(
      i18n.t("Constants.PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title"),
      i18n.t("Constants.PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count"),
      initial
    )
  );
  console.log("submitCODPayment")
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "system", {
        key: "SystemMessage.ReviewRepairRequest",
      }),
      createMessage("REPAIR_DETAILS", "system", ""),
    ])
  );
};

export const saveCCDetails = (ccDetails) => async (dispatch) => {
  await dispatch({
    type: setCCDetails.toString(),
    payload: ccDetails,
  });
  return ccDetails;
};

export const cancelledPayment = () => async (dispatch) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", "Cancel")]));
  dispatch(confirmToCancelRequest("", "Cancelled Payment", true));
};

export const updatePaymentResolvedByChat = (value = true) => async (
  dispatch
) => {
  dispatch({
    type: setPaymentResolvedByChat.toString(),
    payload: value,
  });
};
