import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PropTypes from "prop-types";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "118px",
};

class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        containerStyle={containerStyle}
        initialCenter={{
          lat: this.props.latitude,
          lng: this.props.longitude,
        }}
      >
        <Marker onClick={this.onMarkerClick} name={"Current location"} />
      </Map>
    );
  }
}

MapContainer.defaultProps = {
  latitude: 0,
  longitude: 0,
};

MapContainer.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(MapContainer);
