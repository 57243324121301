import React, { useState } from "react";
import PropTypes from "prop-types";

import { TextBold, TextLight, TextRegular } from "../../base/BaseText";
import BaseButton from "../../base/BaseButton/BaseButton";

import SchedulePickupIcon from "../../images/true/home/icon-schedule-pickup.svg";
import ReturnRepairedPhoneIcon from "../../images/true/home/icon-return-repaired-phone.svg";
import CalendarIcon from "../../images/true/home/icon-calendar-selected.svg";
import BeforeRequestIcon from "../../images/true/home/before-request.svg";

import BackgroundPattern from "../../images/home/background-pattern.png";
import BackgroundTint from "../../images/home/grey-background-tint.png";
import BannerImage from "../../images/home/new-battery-replacement-banner.png";

import HowItWorksImage from "../../images/true/home/screen-battery replacement.png";
import {
  HOMEPAGE_BUTTONS,
  FAQ_LINK,
  START_PREPARATION_LINK,
} from "../../helpers/constants";
import BasePopup from "../../base/BasePopup/BasePopup";
import PickUpAndReturnSchedule from "./HomepageStaticContent/PickUpAndReturnSchedule";
import DeviceEligibilityCriteria from "./HomepageStaticContent/DeviceEligibilityCriteria";
import TurnOffFindMyDevice from "./HomepageStaticContent/TurnOffFindMyDevice";
import InWarrantySupport from "./HomepageStaticContent/InWarrantySupport";

import DesktopBannerBg from "../../images/true/desktop-banner-bg.svg";
import MobileBannerBg from "../../images/true/mobile-banner-bg.svg";
import DesktopBannerFg from "../../images/true/banner-fg.png";
import { Trans, useTranslation } from "react-i18next";

const Banner = ({ onGetStarted: handleGetStarted, language = "th-TH" }) => {
  const { t } = useTranslation();

  return (
    <article className="h-full relative overflow-hidden bg-banner">
      <div>
        <div
          className="hidden lg:block relative"
          style={{
            background: `url(${DesktopBannerBg})`,
            backgroundSize: "cover",
            backgroundPositionX: "100%",
          }}
        >
          <div className="inline-block w-full max-w-58pct relative text-center lg:text-left text-srgray-secondary z-10">
            <div className="ml-auto mr-0 my-20" style={{width: "83%"}}>
              <h1 className="px-8 lg:px-0 pt-8 lg:pt-16 text-white leading-none text-38px">
                <TextBold>{t("HomePage.Banner.Title")}
                <span className={`${t("HomePage.Banner.spacer-en")}`}>&nbsp;&nbsp;</span>
                <br className={`${t("HomePage.Banner.spacer-th")}`} />
                {t("HomePage.Banner.Title2")}!</TextBold>
              </h1>
              <div className="text-1.6 my-10 px-8 lg:px-0 ml-auto">
                <p className="text-white text-22px leading-none mb-10">
                  <Trans i18nKey="HomePage.Banner.Content"></Trans>
                </p>
                <p className="text-white text-22px leading-none mb-10">
                  {t("HomePage.Banner.Content2")}
                </p>
              </div>
              <div className="pb-8 lg:pb-16">
                <BaseButton
                  text={t("HomePage.Banner.Button")}
                  className="mx-auto lg:mx-0 border-white border lg:border-none mt-8 lg:mt-0 rounded rounded-full bg-white text-app-primary text-22px"
                  focused
                  onClick={handleGetStarted}
                />
              </div>
            </div>
          </div>
          <div className="inline-block w-1/2 absolute bottom-0" style={{ height: "calc(100% - 20px)" }}>
            <img
              className="object-cover h-full p-0 mr-auto fhd:ml-24 fhd:pl-16"
              src={DesktopBannerFg}
              alt="Banner"
            />
          </div>
        </div>

        <div
          className="block lg:hidden"
          style={{
            background: `linear-gradient(180deg, #ED1C24 -3.77%, #EC008C 100.14%)`,
          }}
        >
          <div className="inline-block w-full text-left text-srgray-secondary">
            <div className="my-6">
              <h1 className="px-8 lg:px-0 pt-8 lg:pt-16 text-white leading-none text-38px">
                <TextBold>{t("HomePage.Banner.Title")}&nbsp;&nbsp;{t("HomePage.Banner.Title2")}!</TextBold>
              </h1>
              <div className="text-1.6 my-10 px-8 lg:px-0 ml-auto">
                <p className="text-white text-24px leading-none mb-10">
                  <Trans i18nKey="HomePage.Banner.Content"></Trans>
                </p>
                <p className="text-white text-24px leading-none mb-10">
                  {t("HomePage.Banner.Content2")}
                </p>
              </div>
              <div className="pb-6 text-center">
                <BaseButton
                  text={t("HomePage.Banner.Button")}
                  className="border-white border rounded rounded-full bg-white text-app-primary text-28px py-6 px-10 w-auto inline-block"
                  focused
                  onClick={handleGetStarted}
                />
              </div>
            </div>
          </div>

        </div>


      </div>

    </article>
  )
};

const Services = ({ onServiceClick: handleServiceBtnClick }) => {
  const { t } = useTranslation();
  const handleFAQClick = () => {
    window.open(FAQ_LINK, "_blank");
  };

  const handleStartPreparationClick = () => {
    window.location.href="/#/prepare-my-phone"
  };

  return (
    <article className="bg-white w-full">
      <div className="text-center w-full">
        <div className="my-44px text-40px leading-none sm:leading-tight sm:text-3.8 font-bold sm:font-normal">
          <TextRegular>
          {t("HomePage.Body1.Title")}
          </TextRegular>
        </div>

        <div className="flex justify-center flex-row flex-wrap gap-16 sm:gap-6">
          <div className="flex flex-row flex-wrap justify-center gap-16 sm:gap-6">
            <div className="flex flex-col w-full px-10 sm:w-auto sm:px-0">
              <div>
                <img
                  className="mx-auto lg:mx-0 p-0 w-100px height-100px lg:w-70px lg:h-70px"
                  src={BeforeRequestIcon}
                  alt="BeforeRequestIcon"
                />
              </div>
              <div className="sm:w-24r sm:text-left mt-5">
                <div className="text-28px text-center sm:text-left sm:text-2 font-bold">
                  <TextRegular>{t("HomePage.Body1.Card1.Title")}</TextRegular>
                </div>
                <div className="mt-4 leading-none text-22px sm:text-18px">
                  <TextLight>
                  {t("HomePage.Body1.Card1.Content")}
                  </TextLight>
                </div>
                <div className="mt-4">
                  <button
                    className="text-app-primary underline text-22px sm:text-18px"
                    onClick={() => { window.location.href="/#/faq" }
                    }
                  >
                    {t("HomePage.Body1.Card1.LinkText")}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full px-10 sm:w-auto sm:px-0">
              <div>
                <img
                  className="mx-auto lg:mx-0 p-0 w-100px height-100px lg:w-70px lg:h-70px"
                  src={SchedulePickupIcon}
                  alt="SchedulePickupIcon"
                />
              </div>
              <div className="sm:w-24r sm:text-left mt-5">
                <div className="text-28px text-center sm:text-left sm:text-2 font-bold">
                  <TextRegular>{t("HomePage.Body1.Card2.Title")}</TextRegular>
                </div>
                <div className="mt-4 leading-none text-22px sm:text-18px">
                  <TextLight>
                  {t("HomePage.Body1.Card2.Content")}
                  </TextLight>
                </div>
                <div className="mt-4">
                  <button
                    className="text-app-primary underline text-22px sm:text-18px"
                    onClick={handleStartPreparationClick}
                  >
                    {t("HomePage.Body1.Card2.LinkText")}
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div className="flex flex-row flex-wrap justify-center gap-16 sm:gap-6">
            <div className="flex flex-col w-full px-10 sm:w-auto sm:px-0">
              <div>
                <img
                  className="mx-auto lg:mx-0 p-0 w-100px height-100px lg:w-70px lg:h-70px"
                  src={CalendarIcon}
                  alt="CalendarIcon"
                />
              </div>
              <div className="sm:w-24r sm:text-left mt-5">
                <div className="text-28px text-center sm:text-left sm:text-2 font-bold">
                  <TextRegular>
                  {t("HomePage.Body1.Card3.Title")}
                  </TextRegular>
                </div>
                <div className="mt-4 leading-none text-22px sm:text-18px">
                  <TextLight>
                  {t("HomePage.Body1.Card3.Content")}
                  </TextLight>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full px-10 sm:w-auto sm:px-0">
              <div>
                <img
                  className="mx-auto lg:mx-0 p-0 w-100px height-100px lg:w-70px lg:h-70px"
                  src={ReturnRepairedPhoneIcon}
                  alt="ReturnRepairedPhoneIcon"
                />
              </div>
              <div className="sm:w-24r sm:text-left mt-5">
                <div className="text-28px text-center sm:text-left sm:text-2 font-bold">
                  <TextRegular>
                  {t("HomePage.Body1.Card4.Title")}
                  </TextRegular>
                </div>
                <div className="mt-4 leading-none text-22px sm:text-18px">
                  <TextLight>
                  {t("HomePage.Body1.Card4.Content")}
                  </TextLight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

const HowItWorks = ({ onServiceClick }) => {
  const { t } = useTranslation();
  return (
    <article className="bg-srgray-septenary mt-20 sm:mt-7r">
      <div className="flex flex-col sm:flex-row mx-auto w-full lg:w-4/5">
        <div className="w-full sm:w-1/2 h-full pt-20 sm:mt-auto">
          <img
            className="mx-auto md:ml-auto p-0"
            src={HowItWorksImage}
            alt="howItWorksImage"
            width="400px"
          />
        </div>
        <div className="w-full py-5 sm:w-1/2 flex">
          <div className="w-full my-auto p-6 sm:p-0 sm:pr-24">
            <div className="text-34px leading-none sm:text-6xl sm:text-leading-tight">
              <TextRegular><Trans i18nKey="HomePage.Body2.Title"></Trans></TextRegular>
            </div>
            <div className="mt-6 text-22px sm:text-18px leading-none">
              <TextRegular>
                {t("HomePage.Body2.Content1")}
              </TextRegular>
            </div>

            <div className="mt-6 text-22px sm:text-18px leading-none">
              <TextRegular>
              {t("HomePage.Body2.Content2")}
              </TextRegular>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
};

const ServiceModal = ({ isOpen, open, selectedService }) => {
  const { t } = useTranslation();
  let content = null;

  if (selectedService === HOMEPAGE_BUTTONS.ELIGIBILITY_CHECK_ARTICLE) {
    content = <DeviceEligibilityCriteria title="Device Eligibility Criteria" />;
  }

  if (selectedService === HOMEPAGE_BUTTONS.PICK_UP_AND_RETURN_SCHEDULE) {
    content = (
      <PickUpAndReturnSchedule title="Pick-up and Return Time Schedule" />
    );
  }

  if (selectedService === HOMEPAGE_BUTTONS.START_PREPARATION) {
    content = <TurnOffFindMyDevice title="Turn off Find My Device" />;
  }

  if (selectedService === "IN_WARRANTY_SUPPORT") {
    content = <InWarrantySupport title="In-Warranty Support" />;
  }

  return (
    <BasePopup
      show={isOpen}
      global
      onClose={() => open(false)}
      title={null}
      className="w-11/12 md:w-2/3 lg:1/3"
    >
      {content}
    </BasePopup>
  );
};

const HomeBody = ({ onGetStarted }) => {
  const { t } = useTranslation();
  const [selectedButton, setSelectedButton] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleServiceBtnClick = (selectedService) => {
    setIsOpen(true);
    setSelectedButton(selectedService);
  };

  return (
    <div className="flex flex-col">
      <Banner onGetStarted={onGetStarted} />
      <Services
        onServiceClick={(selectedService) =>
          handleServiceBtnClick(selectedService)
        }
      />
      <HowItWorks
        onServiceClick={(selectedService) =>
          handleServiceBtnClick(selectedService)
        }
      />
      <ServiceModal
        open={setIsOpen}
        selectedService={selectedButton}
        isOpen={isOpen}
      />
    </div>
  );
};

HomeBody.propTypes = {
  onGetStarted: PropTypes.func.isRequired,
};

export default HomeBody;
