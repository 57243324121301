import { setChatConnect, setChatInputVisibility } from "./reducers";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";
import { endFlow, updateFlowProgress } from "../../../actions/flowWindow";
import { PROGRESS_DATA } from "../../../helpers/constants";
import { isTimeBetweenBusinessHour, getBusinessStartAndEndTime } from "../../../helpers/dateUtils";
import { validateNric } from "../../Validation/state/operators";
import { updatePaymentResolvedByChat } from "../../RepairClaim/state/operators/paymentOperator";
import { setImeiResolvedByChat } from "../../RepairClaim/state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";
import i18n from 'i18next';

export const updateChatConnect = (status) => ({
  type: setChatConnect.toString(),
  payload: status,
});

export const udpateChatInputVisibility = (status) => (dispatch, getStore) => {
  const businessHours = getStore().session.businessHours;
  const { isActive, startTime, endTime } = getBusinessStartAndEndTime(businessHours);

  /**
   * isActive: true chat functionality will work for given timings
   * isActive: false chat functionality will be disabled
   */ 
  if ((isActive ?? true) && isTimeBetweenBusinessHour(startTime, endTime)) {
    dispatch({
      type: setChatInputVisibility.toString(),
      payload: status,
    });
  } else {
    dispatch(
      addJourneyMessages([
        createMessage("CHAT", "system", {
          showComponent: "NoAgentsAvailable",
        }),
      ])
    );
  }
};

export const resumeRepairFlow = (code) => async (dispatch, getStore) => {
  const { firstName } = getStore().validation.inputData;
  const { status, chatReason } = getStore().chat;
  if (isEmpty(status) && isEmpty(chatReason)) return;
  switch (code) {
    case "Resolved":
      const { chatReason } = getStore().chat;
      if (chatReason === "Invalid Name") {
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.AskForNric",
              values: { firstName },
            }),
            createMessage("VALIDATION", "system", { showComponent: "AskNric" }),
          ])
        );
      } else if (chatReason === "Invalid Nric") {
        // dispatch(validateNric("AGENT_VERIFIED", true));
        dispatch(
          addJourneyMessages([
            createMessage("VALIDATION", "system", { showComponent: "AskNric" }),
          ])
        );
      } else if (
        chatReason === "Invalid Imei" ||
        chatReason === "I Don't Know My IMEI"
      ) {
        const initial = getStore().journeyMessages.flowProgress.percentage;
        await dispatch(
          updateFlowProgress(
            i18n.t("Constants.PROGRESS_DATA.TROUBLESHOOT.title"),
            i18n.t("Constants.PROGRESS_DATA.TROUBLESHOOT.count"),
            initial
          )
        );
        await dispatch(setImeiResolvedByChat());
        dispatch(
          addJourneyMessages([
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "verifyImei",
            }),
          ])
        );
      } else if (chatReason === "Invalid Card") {
        dispatch(updatePaymentResolvedByChat());
        dispatch(
          addJourneyMessages([
            createMessage("PAYMENT", "system", {
              showComponent: "SubmitCardDetails",
            }),
          ])
        );
      } else if (
        chatReason === "General Inquiry" ||
        chatReason === "In-WarrantyRequest" ||
        chatReason === "ChangeSchedule" ||
        chatReason === "CancelRequest"
      ) {
        dispatch(
          addJourneyMessages([createMessage("ENQUIRY_OPTIONS", "system", {})])
        );
      }
      break;
    case "User Disconnected":
      dispatch(
        addJourneyMessages([
          createMessage("CHAT", "system", {
            showComponent: "UserDisconnected",
          }),
        ])
      );
      break;
    case "Agent Disconnected":
      dispatch(
        addJourneyMessages([
          createMessage("CHAT", "system", {
            showComponent: "TechnicalIssue",
          }),
        ])
      );
      break;
    default:
      dispatch(
        addJourneyMessages([
          createMessage("CHAT", "system", {
            showComponent: "ChatUnresolved",
          }),
        ])
      );
      break;
  }
};

export const closeAgentBusy = (status) => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  dispatch(endFlow());
};

export const retryChat = (status) => (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  dispatch(udpateChatInputVisibility(true));
};
